import { configureStore } from '@reduxjs/toolkit';
import user from './modules/userSlice';
import selectedLinkReducer from './modules/selectedLinkSlice'
import selectedCompany from './modules/selectedCompany';
import selectedMoreCompany from './modules/selectedMoreAbout'
import selectedLanguage from './modules/selectedLanguage';


const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = configureStore({
  reducer: {
    user: user,
    selectedLink: selectedLinkReducer,
    selectedCompany: selectedCompany,
    selectedMoreCompany: selectedMoreCompany,
    selectedLanguage: selectedLanguage
    //ADD HERE MORE RECUCERS IF I NEED
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem('reduxState', JSON.stringify(state));
});

export default store;
