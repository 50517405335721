import "./usercomponent.scss";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeLoggedInUser } from "../../services/Auth";
import userImg from "../../assets/imgs/default_avatar.jpg";
import { AiOutlineCaretDown } from "react-icons/ai";
import { RiLogoutBoxLine } from "react-icons/ri";
import { logout } from "../../Store/modules/userSlice";
import { useDispatch } from "react-redux";
import { logoutSelectedLink } from "../../Store/modules/selectedLinkSlice";
import { logoutSelectedCompany } from "../../Store/modules/selectedCompany";
import { logoutSelectedMoreCompany } from "../../Store/modules/selectedMoreAbout";
import { useTranslation } from "react-i18next";
import { logoutSelectedLanguage } from "../../Store/modules/selectedLanguage";

const ICON_SIZE = 10;

function UserComponent(props) {
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation()

  // MENU DROPDOWN
  const [openUser, setOpenUser] = useState(false);
  const menuDrop = useRef();
  const imgDrop = useRef();

  // Effect to handle clicks outside the dropdown menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuDrop.current &&
        !menuDrop.current.contains(event.target) &&
        imgDrop.current !== event.target
      ) {
        setOpenUser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuDrop, imgDrop]);
  // END MENU DROPDOWN

  // Function to handle user logout
  const handleLogout = () => {
    dispatch(logout());
    dispatch(logoutSelectedLink())
    dispatch(logoutSelectedCompany())
    dispatch(logoutSelectedMoreCompany())
    dispatch(logoutSelectedLanguage())
    props.callback()
    removeLoggedInUser();
    navigate("/Pages/LoginPage/");
  };

  // Function to render user's initials
  const renderUserInitial = () => {
    const initial = user?.name ? user.name.charAt(0).toUpperCase() : "";
    return <p className="firstLetter">{initial}</p>;
  };

  return (
    <>
      {/* {user && ( */}
      <div className="relative" onClick={() => setOpenUser(!openUser)}>
        <div className="d-flex align-items-center cursorPointer">
          {renderUserInitial()}
          <img ref={imgDrop} src={userImg} alt="user" className="usrClass" />
          {/* <small className="ml-1">{user?.name}</small> */}
          <AiOutlineCaretDown
            className="ml"
            style={{ marginLeft: "6px" }}
            size={ICON_SIZE}
          />
        </div>
        {openUser && (
          <div ref={menuDrop} className="dropInfo absolute">
            <ul className="d-flex column p-0">
              {/* <Link
                to="/pages/settingsPage/Settings"
                className="btnDropDown justify-content-space-around align-items-center d-flex p-16"
              >
                Definições
              </Link> */}
              <button
                onClick={handleLogout}
                className="btnDropDown p-16 justify-content-space-around align-items-center d-flex bt-1"
              >
                <RiLogoutBoxLine /> {t('Sair')}
              </button>
            </ul>
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
}

export default UserComponent;
