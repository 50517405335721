import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation messages for different languages
import enTranslation from '../locales/en/messages.json';
import ptTranslation from '../locales/pt/messages.json';


// Define translation resources
const resources = {
  en: { translation: enTranslation },
  pt: { translation: ptTranslation },
};
const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));


// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources,              // Pass the defined translation resources
    lng: loggedInUser?.lang,              // Set default language
    fallbackLng: loggedInUser?.lang,      // Fallback language
    interpolation: {
      escapeValue: false,   // Do not escape values in translations
    },
  });

export default i18n;

