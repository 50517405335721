import { createSlice } from "@reduxjs/toolkit";

export const selectedMoreCompanySlice = createSlice({
  name: "selectedMoreCompany",
  initialState: {
    data: null,
  },
  reducers: {
    setSelectedMoreCompany: (state, action) => {
      state.data = action.payload.data;
    },
    logoutSelectedMoreCompany: (state) => {
      state.data = null;
    },
  },
});

// Export the action creator separately
export const { setSelectedMoreCompany, logoutSelectedMoreCompany } = selectedMoreCompanySlice.actions;

// Export the reducer
export default selectedMoreCompanySlice.reducer;
