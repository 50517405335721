import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import './index.scss'
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import NavBar from "./components/navBar/NavBar";
import Login from "./pages/LoginPage/Login";
import HomePage from "./pages/HomePage/homePage";
import MoreAbout from "./pages/MoreAbout/moreAbout";
//import GraphicsPage from "./pages/Graphics/graphics";


function App() {
    const [contentHeight, setContentHeight] = useState(0);
    const [showSideBar, setShowSideBar] = useState(false);
    const user = useSelector((state) => state.user.data);
    const navigate = useNavigate()
    //Calculate the height dynamically 
    useEffect(() => {
        // Function to calculate and set the height
        const calculateHeight = () => {
            const contentElement = document.getElementById('content');
            if (contentElement) {
                const height = contentElement.offsetHeight - 60; // Subtract 60 pixels
                setContentHeight(height);
            }
        };

        // Call the function initially and on window resize
        calculateHeight();
        window.addEventListener('resize', calculateHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, []);

    const toggleSidebar = () => {
        setShowSideBar(!showSideBar);
    };

    useEffect(() => {
        //console.log("user", user);
        if (user != null) {
            //console.log("Entrou");
            navigate("/Pages/HomePage/")
        } else {
            navigate("/Pages/LoginPage/")
            //console.log("Nao");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="App">
            <div className="App-header">
                <ToastContainer style={{ fontSize: "14px" }} />
                <NavBar toggleSidebar={toggleSidebar} />
                <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", minHeight: `${contentHeight}px` }}
                    >
                        <Routes>
                            <Route path="/Pages/LoginPage/" element={<Login />} />
                            <Route path="/Pages/HomePage/*" element={<HomePage />} />
                            <Route path="/Pages/MoreAbout/*" element={<MoreAbout />} />
                            {/* <Route path="/Pages/graphics/" element={<GraphicsPage />} /> */}
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RouterApp() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}

export default RouterApp;

