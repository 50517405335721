import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import './translation/i18n'
import i18n from './translation/i18n';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './Store/store';

const root = createRoot(document.getElementById('root'));

root.render(
  // I18nextProvider used to translation
  <I18nextProvider i18n={i18n}>
    {/* <React.StrictMode> */}
      <Provider store={store}>
        <App />
      </Provider>
    {/* </React.StrictMode> */}
  </I18nextProvider>,
);


