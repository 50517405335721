import { useDispatch, useSelector } from "react-redux";
import TableToUse from "../../components/tableToUse/table";
import { SendRequest } from "../../function/General_Functions";
import { GETPROPSAPP } from "../../constants/endpoints";
import { useEffect, useState } from "react";
import withAuth from "../../services/withAuth";
import { useTranslation } from "react-i18next";


function HomePage() {
    const dispatch = useDispatch();
    const selectedLink = useSelector((state) => state.selectedLink.data);
    const selectedCompany = useSelector((state) => state.selectedCompany.data);
    const user = useSelector((state) => state.user.data);
    const [infoVersion, setInfoVersion] = useState({})
    const { t } = useTranslation();
    const selectedLanguage = useSelector((state) => state.selectedLanguage.data);

    const callProps = () => {
        let url = `${GETPROPSAPP}`
        SendRequest(
            "get",
            url,
            (successData) => {
                setInfoVersion(successData.result)
                //console.log(successData.result);
                if (successData?.result?.length === 0) {
                    setInfoVersion([]);
                } else {
                    //console.log("Success:", successData.result);
                    setInfoVersion(successData.result)
                }
            },
            (error) => {
                console.log("error", error);
            },
            dispatch, selectedLanguage
        )
    };

    useEffect(() => {
        callProps()
        //console.log("entrou");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className="padding22">
            <div>
                <h4>{t(selectedLink?.name)}</h4>
            </div>
            {(selectedCompany && selectedLink) ? (
                <TableToUse />
            ) :
                (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 138px)' }}>
                        <p className="mb-0" ><strong>{t('Selecionar cliente e encomendas ou artigos em stock.')}</strong></p>
                    </div>
                )}
            <div style={{
                textAlign: 'right', marginTop: selectedCompany == null ? '-24px' : '14px'
            }}>
                <small style={{ opacity: '0.5' }}>
                    {Object.entries(infoVersion).map(([key, value]) => (
                        <strong key={key}>{key}: {value} | </strong> 
                    ))}
                </small>
            </div>
        </div >
    );
}

export default withAuth(HomePage);