import "./login.scss";
import React, { useState } from "react";
import axios from "axios";
import { saveAuthToken, saveLoggedInUser } from "../../services/Auth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { POSTLOGIN } from "../../constants/endpoints";
import { login } from "../../Store/modules/userSlice";
import logo from "../../assets/imgs/imgs/logo__white.svg";
import logo_hfa from "../../assets/imgs/imgs/logo_white_hfa.svg";

function Login() {
    const dispatch = useDispatch();
    const selectedLanguage = useSelector((state) => state.selectedLanguage.data);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [setError] = useState(null);

    function handleSubmit(event) {
        event.preventDefault();

        let data = JSON.stringify({
            username: username,
            password: password,
        });
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: POSTLOGIN,
            headers: {
                "Content-Type": "application/json",
                'Accept-Language': "pt"
            },
            data: data,
        };
        //console.log(data);

        axios
            .request(config)
            .then((response) => {
                if (response.status === 200) {
                    //console.log("entrou");
                    // console.log(JSON.stringify(response.data));
                    let rjson = response.data;
                    //console.log(rjson);
                    // if (!rjson.hasOwnProperty("token")) {
                    //     return console.log("Body is missing token key");
                    // }
                    let token = rjson.login.token;
                    saveAuthToken(token);
                    //console.log("rjson", rjson);
                    let userId = rjson.login.erps[0].cgID;
                    let firstName = rjson.login.firstName;
                    let companies = rjson.companies
                    let lang = selectedLanguage?.lang
                    //let userEmail = rjson.login.erps.erps;
                    saveLoggedInUser(firstName, userId, companies, lang);
                    dispatch(
                        login({
                            data: {
                                name: firstName,
                                id: userId,
                                companies: companies,
                                //email: userEmail,
                            },
                        })
                    );
                    navigate("/Pages/HomePage/")
                } else {
                    setError("Login falhado. tente novamente");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Login falhado. tente novamente");
            });
    }

    const handleKeyPress = (e) => {
        if (e && e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <div className="imgbg">
            <div className="bodyform">
                <div>
                    <Row className="mb-3 justify-content-center">
                        <Col md={4} className="w-img">
                            <img className="logo_Login" src={logo_hfa} alt="logo" />
                        </Col>
                    </Row>
                    <Row className="mb-2 justify-content-center">
                        <Col md={3} className="w-img">
                            <img className="logo_Login" src={logo} alt="logo" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="mb-1" sm={12} md={9}>
                            <label className="colorWhite">Utilizador</label>
                            <input type="text" onKeyDown={handleKeyPress} onChange={(e) => setUsername(e.target.value)} />
                        </Col>
                        <Col className="mb-2" sm={12} md={9}>
                            <label className="colorWhite">Password</label>
                            <input type="password" onKeyDown={handleKeyPress} onChange={(e) => setPassword(e.target.value)} />
                        </Col>
                        <Col sm={12} md={9}>
                            <button className="btnLogin" onClick={handleSubmit} >Entrar</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Login;
