export const articleInformation = [
    { label: "Codigo_artigo_HFA", description: "Identificador_do_artigo_na_HFA", state: "mp" },
    { label: "Descricao", description: "Descricao_do_artigo", state: "mp" },
    { label: "Stock_Disponivel", description: "Quantidade_unidades_disponivel_deste_artigo_na_hfa", state: "mp" },
    { label: "Stock_Reservado", description: "Quantidade_(unidades)_do_artigo_com_encomenda_de_destino_e_que_esta_em_producao", state: "mp" },
    { label: "Stock_Encomendado", description: "Stock_Encomendado_desc", state: "mp" },
    { label: "Manufacturer", description: "Manufacturer_desc", state: "mp" },
    { label: "Manufacturer_part_number", description: "Manufacturer_part_number_desc", state: "mp" },
    { label: "Supplier", description: "Supplier_desc", state: "mp" },
    { label: "Supplier_part_number", description: "Supplier_part_number_desc", state: "mp" },

    { label: "Codigo_artigo_HFA", description: "Identificador_do_artigo_na_HFA", state: "prodacabado" },
    { label: "Descricao", description: "Descricao_do_artigo", state: "prodacabado" },
    { label: "Stock_Disponivel", description: "Quantidade_(unidades)_do_artigo_sem_encomenda_de_destino", state: "prodacabado" },

    { label: "Codigo_artigo_HFA", description: "Identificador_do_artigo_na_HFA", state: "comppart" },
    { label: "Descricao", description: "Descricao_do_artigo", state: "comppart" },
    { label: "Stock_Disponivel", description: "Quantidade_(unidades)_do_artigo_sem_encomenda_de_destino", state: "comppart" },

    { label: "ID", description: "Codigo_da_encomenda_na_base_de_dados", state: "open" },
    { label: "Numero_Encomenda_HFA", description: "Codigo_Encomenda_na_HFA", state: "open" },
    { label: "Data", description: "Data_de_Criacao", state: "open" },
    { label: "Codigo_artigo_HFA", description: "Identificador_do_artigo_na_HFA", state: "open" },
    { label: "Codigo_Requisicao_Cliente", description: "Codigo_da_requisicao_do_cliente", state: "open" },
    { label: "Produto", description: "Nome_do_produto", state: "open" },
    { label: "Data_Entrega_Prevista", description: "Data_Entrega_Prevista_ao_Cliente", state: "open" },
    { label: "Quantidade_Encomendada", description: "Quantidade_Encomendada_Pelo_Cliente", state: "open" },
    { label: "Quantidade_Satisfeita", description: "Quantidade_Entregue_ao_Cliente", state: "open" },
    { label: "Quantidade_Pendente", description: "Quantidade_Por_Entreguar_ao_Cliente", state: "open" },
    { label: "Data_Entrega_Efetiva", description: "Data_Que_Foi_Realmente_Entregue", state: "open" },

    { label: "ID", description: "Codigo_da_encomenda_na_base_de_dados", state: "closed" },
    { label: "Numero_Encomenda_HFA", description: "Codigo_Encomenda_na_HFA", state: "closed" },
    { label: "Data", description: "Data_de_Criacao", state: "closed" },
    { label: "Codigo_artigo_HFA", description: "Identificador_do_artigo_na_HFA", state: "closed" },
    { label: "Codigo_Requisicao_Cliente", description: "Codigo_da_requisicao_do_cliente", state: "closed" },
    { label: "Produto", description: "Nome_do_produto", state: "closed" },
    { label: "Data_Entrega_Prevista", description: "Data_Entrega_Prevista_ao_Cliente", state: "closed" },
    { label: "Quantidade_Encomendada", description: "Quantidade_Encomendada_Pelo_Cliente", state: "closed" },
    { label: "Quantidade_Satisfeita", description: "Quantidade_Entregue_ao_Cliente", state: "closed" },
    { label: "Quantidade_Pendente", description: "Quantidade_Por_Entreguar_ao_Cliente", state: "closed" },
    { label: "Data_Entrega_Efetiva", description: "Data_Que_Foi_Realmente_Entregue", state: "closed" },

    { label: "ID_Guia_de_Remessa", description: "ID_Guia_de_Remessa_desc", state: "" },
    { label: "ID_Linha_Encomenda", description: "ID_Linha_Encomenda_desc", state: "" },
    { label: "Data", description: "Data_de_Criacao", state: "" },
    { label: "Quantidade", description: "Quantidade_Da_Encomenda", state: "" },
    { label: "Guia_de_Remessa", description: "Guia_de_Remessa_desc", state: "" },
    { label: "Fatura", description: "Fatura_desc", state: "" },
    { label: "Codigo_artigo_HFA", description: "Identificador_do_artigo_na_HFA", state: "" },
    { label: "Ref_Documento_HFA", description: "Ref_Documento_HFA_desc", state: "" },
];