import axios from "axios";
import { getAuthToken, removeLoggedInUser } from "../services/Auth";
import { logoutSelectedLink } from "../Store/modules/selectedLinkSlice";
import { logoutSelectedCompany } from "../Store/modules/selectedCompany";
import { logout } from "../Store/modules/userSlice";
import { logoutSelectedMoreCompany } from "../Store/modules/selectedMoreAbout";

export const SendRequest = async (methodUsed, url, successCallback, errorCallback, dispatch, selectedLanguage) => {
    try {
        const token = getAuthToken();
        console.log("selectedLanguage", selectedLanguage);

        const config = {
            method: methodUsed,
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: `token ${token}`,
                'Accept-Language': selectedLanguage.lang
            },
        };
        //console.log(config);

        const response = await axios.request(config);
        //console.log(url);
        //console.log(`${selectedLanguage?.lang}`);
        //console.log(response.data.detail[0]);
        if (response.status === 200) {
            //console.log(response.data);
            successCallback({
                result: response.data
            });
        } else {
            errorCallback("Failed to get List");
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log("Response status:", error.response.status);
            //console.log("Response data:", error.response.data);

            if (error.response.status === 401) {
                //console.log("401 Unauthorized");
                successCallback({
                    result: "erro",
                });
                dispatch(logoutSelectedLink());
                dispatch(logoutSelectedCompany());
                dispatch(logout());
                dispatch(logoutSelectedMoreCompany());
                removeLoggedInUser();
            } else {
                errorCallback(`Failed to get List. Status: ${error.response.status}`);
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log("No response received");
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up the request:", error.message);
        }
    }
}
