/* eslint-disable react-hooks/exhaustive-deps */
import "./navbar.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/imgs/imgs/logo__white.svg";
import logo_hfa from "../../assets/imgs/imgs/logo_white_hfa.svg";
import UserComponent from "../userComponent/userComponent";
import { logoutSelectedLink, setSelectedLink } from "../../Store/modules/selectedLinkSlice";
import { logoutSelectedCompany, setSelectedCompany } from "../../Store/modules/selectedCompany";
import { useEffect, useState } from "react";
import { logoutSelectedMoreCompany } from "../../Store/modules/selectedMoreAbout";
import { useTranslation } from "react-i18next";
import { setSelectedLanguage } from "../../Store/modules/selectedLanguage";
import { saveLoggedInUser } from "../../services/Auth";


function NavBar(props) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const company = useSelector((state) => state.selectedCompany.data);
    const [selectedCompanyToSecond, setSelectedCompanyToSecond] = useState("")
    const { t, i18n } = useTranslation();

    // Array of navigation links
    const navLinks = [
        { label: t('Encomendas'), linkText: 'Encomendas', state: 'open' },
        { label: t('Artigos em Stock'), linkText: 'Artigos em Stock', state: 'mp' },
        // Add more links as needed
    ];

    // Function to change the language
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        dispatch(
            setSelectedLanguage({
                data: {
                    lang: language
                }
            })
        )
    };
    // Function to handle link click and update selected link in the Redux store
    const handleLinkClick = (linkType, nameTitle) => {
        dispatch(
            setSelectedLink({
                data: {
                    name: linkType,
                    nameToSelect: nameTitle
                },
            })
        );
    };

    // Function to set the selected company when the user changes it
    const selectedCompanyActive = (companyId) => {
        dispatch(
            setSelectedCompany({
                data: {
                    id: companyId,
                    name: user.companies[companyId],
                },
            })
        );
    }

    // Effect to set the selected company when the user has only one company
    useEffect(() => {
        if (user && user.companies && Object.keys(user.companies).length === 1) {
            const [firstCompanyId] = Object.entries(user.companies)[0];
            const nameCompany = user.companies[firstCompanyId];
            //console.log(firstCompanyId);
            setSelectedCompanyToSecond(firstCompanyId)
            dispatch(
                setSelectedCompany({
                    data: {
                        id: firstCompanyId,
                        name: nameCompany
                    },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Function to close selected links and companies
    const closeSelectedLink = () => {
        if (user && user.companies && Object.keys(user.companies).length === 1) {
            dispatch(logoutSelectedLink())
            dispatch(logoutSelectedMoreCompany())
        } else {
            dispatch(logoutSelectedLink())
            dispatch(logoutSelectedCompany())
            dispatch(logoutSelectedMoreCompany())
            setSelectedCompanyToSecond("")
        }
    }

    // Function to set the selected company when the user changes it
    useEffect(() => {
        //console.log("selectedCompanyToSecond", selectedCompanyToSecond);
        setSelectedCompanyToSecond(company?.id)
    }, [selectedCompanyToSecond]);

    const handleLanguageChange = (e) => {
        const newLanguage = e.target.value;
        changeLanguage(newLanguage);
        saveLoggedInUser(user.name, user.id, user.companies, newLanguage);
      };

      const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

    return (
        <>
            {user && (
                <header className="d-flex justify-content-space-between align-items-center">
                    <div className="d-flex headerNav">
                        <Link className="d-flex align-items-end" onClick={closeSelectedLink} to="/pages/HomePage/">
                            <img className="logo_hfa" src={logo_hfa} alt="logo" />
                            <img className="logo" src={logo} alt="logo" />
                        </Link>
                        <nav className="ml-2 d-flex align-items-center marginTopToMobile">
                            {navLinks.map((link) => (
                                <Link
                                    key={link.linkText}
                                    className="navBarLinks mr-1"
                                    onClick={() => handleLinkClick(link.linkText, link.state)}
                                    to={`/Pages/HomePage/${link.linkText}`}
                                >
                                    {link.label}
                                </Link>
                            ))}
                        </nav>
                    </div>
                    <div className="d-flex align-items-center">
                        {/* <label className="mr-1" style={{ color: 'white' }}>{t('Cliente')}</label> */}
                        <select value={selectedCompanyToSecond} className="mr-1" onChange={(e) => {
                            setSelectedCompanyToSecond(e.target.value);
                            selectedCompanyActive(e.target.value);
                        }}>
                            {Object.entries(user.companies).length === 1 ? (
                                // If there is only one option, select it by default
                                Object.entries(user.companies)
                                    .map(([companyId, companyName]) => (
                                        <option key={companyId} value={companyId} selected>
                                            {companyName}
                                        </option>
                                    ))
                            ) : (
                                // If there are multiple options, display the default option along with others
                                <>
                                    <option value="">{t('Selecionar Cliente')}</option>
                                    {Object.entries(user.companies)
                                    // sort the options alphabetically by the company name
                                    .sort(([, companyNameA], [, companyNameB]) => companyNameA.localeCompare(companyNameB))
                                    .map(([companyId, companyName]) => (
                                        <option key={companyId} value={companyId}>
                                            {companyName}
                                        </option>
                                    ))}
                                </>
                            )}
                        </select>
                        <select style={{ width: 'auto', backgroundColor: '#002C4B', color: 'white' }} className="mr-1" onChange={(e) => {changeLanguage(e.target.value); handleLanguageChange(e)}} value={loggedInUser?.lang}>
                            <option value="en">EN</option>
                            <option value="pt">PT</option>
                        </select>
                        <UserComponent callback={() => {
                            changeLanguage("pt")
                            setSelectedCompanyToSecond("")
                        }} />
                    </div>

                </header>
            )}
        </>
    );
}

export default NavBar;
