import React, { useState, useEffect, useRef } from "react";
import "./tableMore.scss";
import { ORDERSDELIVERIES } from "../../constants/endpoints";
import { RingLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SendRequest } from "../../function/General_Functions";
import { articleInformation } from "../messagesToUse/list";
import MessageToUse from "../messagesToUse/MessagesToUse";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";

const ICON_SIZE = 18;

function TableMore(props) {
    const navigate = useNavigate()
    const selectedLanguage = useSelector((state) => state.selectedLanguage.data);
    const selectedMoreAboutCompany = useSelector((state) => state.selectedMoreCompany.data);
    const [infoTable, setInfoTable] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [seeBalloon, setSeeBalloon] = useState(false)
    const balloonRef = useRef(null);
    const { t } = useTranslation();

    // Function to toggle visibility of the information balloon
    const iWantToSee = () => {
        setSeeBalloon(!seeBalloon)
    }

    // Effect to close the information balloon when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (balloonRef.current && !balloonRef.current.contains(event.target)) {
                iWantToSee(setSeeBalloon);  // Use the imported function
            }
        };

        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seeBalloon]);

    // Function to fetch deliveries data
    const getDeliveries = () => {
        setLoading(true)
        let url = `${ORDERSDELIVERIES}${selectedMoreAboutCompany?.value?.ID}`;
        SendRequest(
            "get",
            url,
            (successData) => {
                //console.log(successData);
                if (successData?.result === "erro") {
                    navigate("/pages/LoginPage/")
                } else {
                    //console.log(successData.result.result);
                    if (successData.result.result.length === 0) {
                        setInfoTable([]);
                        setColumnNames([])
                        setLoading(false)
                    } else {
                        setInfoTable(successData.result.result);
                        setColumnNames(Object.keys(successData.result.result[0]))
                        setLoading(false)
                    }
                }
            },
            (error) => {
                console.log("error", error);
            },
            dispatch,
            selectedLanguage
        )
    }

    // Effect to fetch deliveries data when selectedMoreAboutCompany or selectedLanguage changes
    useEffect(() => {
        getDeliveries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMoreAboutCompany, selectedLanguage]);

    return (
        <div>
            {loading ? (
                <div className="loading-spinner">
                    <RingLoader size={100} color={"#123abc"} loading={loading} />
                    {/* <p className="mb-0" style={{ marginLeft: '24px' }}>Loading...</p> */}
                </div>
            ) : (
                <>
                    <div className="d-flex justify-content-end">
                        <button style={{ background: 'none', border: 'none' }} onClick={() => iWantToSee()} onMouseOver={() => iWantToSee()} >
                            <FaInfoCircle color={'blue'} size={ICON_SIZE} />
                        </button>
                        {seeBalloon === true ? (
                            <div ref={balloonRef} className="informationBalloon">
                                <small>
                                    {articleInformation.map((item) =>
                                        item.state === "" ? (
                                            <MessageToUse key={item.label} title={t(item.label)} text={t(item.description)} />
                                        ) : (<></>)
                                    )}
                                </small>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="mt-4" style={{ overflowX: 'auto' }}>
                        <table className="mt-4 ">
                            <thead style={{ borderBottom: columnNames.length > 0 ? '1px solid lightgray' : "none" }} className="colorHead">
                                <tr style={{ cursor: 'auto' }}>
                                    {columnNames.map((columnName) => (
                                        <th key={columnName}>{columnName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {infoTable.length > 0 ? (
                                    infoTable.map((value) => (
                                        <tr className="tableTr" key={value['Guia de Remessa']} style={{ cursor: 'auto' }}>
                                            {columnNames.map((columnName) => (
                                                <td className="hideText" title={value[columnName]} key={columnName}>{value[columnName]}</td>
                                            ))}
                                        </tr>
                                    ))
                                ) :

                                    (
                                        <tr>
                                            <td className="test"></td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default TableMore;
