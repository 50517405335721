const SESSION_KEY = 'loggedInUser';
const AUTHTOKEN_KEY = 'authtoken';

export const saveLoggedInUser = (user, id, companies, lang) => {
  localStorage.setItem(SESSION_KEY, JSON.stringify({"name":user, "id":id, "companies":companies, "lang":lang}));
};

export const saveAuthToken = (token) => {
  localStorage.setItem(AUTHTOKEN_KEY, token);
};

export const getAuthToken = () => {
  return localStorage.getItem(AUTHTOKEN_KEY);
};


export const getLoggedInUser = () => {
  const user = sessionStorage.getItem(SESSION_KEY);
  return JSON.parse (user)

};

export const removeLoggedInUser = () => {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem(AUTHTOKEN_KEY);
};
