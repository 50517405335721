let apihost = "";
let wshost = "";
try {
	//console.log(window.location.hostname);
	//console.log(window.location.port);
	// eslint-disable-next-line eqeqeq
	let protocol = window.location.protocol == "https:" ? "https:" : "http:";
	// eslint-disable-next-line eqeqeq
	let wsprotocol = window.location.protocol == "https:" ? "wss:" : "ws:";
	apihost = window.location.port
		? `${protocol}//${window.location.hostname}:${window.location.port}`
		: `${protocol}//${window.location.hostname}`;
	wshost = window.location.port
		? `${wsprotocol}//${window.location.hostname}:${window.location.port}`
		: `${wsprotocol}//${window.location.hostname}`;
} catch {
	console.log("URL Error");
}

export const APIHOST = apihost;
export const WSHOST = wshost;


//EndPoints Users
export const POSTLOGIN = `${APIHOST}/api/v1/auth/login`;

//EndPoint 
export const ORDEROPENORCLOSED = `${APIHOST}/api/v1/extranet/`;
export const ORDERSDELIVERIES = `${APIHOST}/api/v1/extranet/getdeliveriesbyfacliid/json?facliid=`;

//DOwnload Orders Open and CLOSED CSV
export const DOWNLOADORDERSOPEN = `${APIHOST}/api/v1/extranet/orders-open/csv?companyid=`
export const DOWNLOADORDERSOPCLOSED = `${APIHOST}/api/v1/extranet/orders-closed/csv?companyid=`
export const STOCKGET = `${APIHOST}/api/v1/extranet/stock/`

//DOWNLOAD FILE CSV STOCK
export const DOWNLOADSTOCKMP = `${APIHOST}/api/v1/extranet/stock/mp/csv?companyid=`;
export const DOWNLOADSTOCKPRODACABADO = `${APIHOST}/api/v1/extranet/stock/prodacabado/csv?companyid=`;
export const DOWNLOADSTOCKCOMPPART = `${APIHOST}/api/v1/extranet/stock/comppart/csv?companyid=`;

//Props App
export const GETPROPSAPP = `${APIHOST}/api/v1/base/info`;

