import { useSelector } from "react-redux";
import TableMore from "../../components/tableMore/tableMore";
import withAuth from "../../services/withAuth";
import { useTranslation } from "react-i18next";

function MoreAbout(props) {
    const selectedLink = useSelector((state) => state.selectedLink.data);
    const selectedMoreAboutCompany = useSelector((state) => state.selectedMoreCompany.data);
    const { t } = useTranslation();

    return (
        <>
            <div className="padding22">
                <div style={{ marginBottom: '52px' }}>
                    <h4>{selectedLink?.name}</h4>
                </div>
                <div style={{ marginTop: '52px' }}>
                    {Object.entries(selectedMoreAboutCompany?.value).map(([param, value]) => (
                        <p style={{ marginBottom: '4px' }} key={param}>
                            <strong>{t(param)}:</strong> {t(value)}
                        </p>
                    ))}
                </div>
                <TableMore />
            </div>
        </>
    );
}

export default withAuth(MoreAbout);