/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./table.scss";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { getAuthToken } from "../../services/Auth";
import axios from "axios";
import { DOWNLOADORDERSOPCLOSED, DOWNLOADORDERSOPEN, DOWNLOADSTOCKCOMPPART, DOWNLOADSTOCKMP, DOWNLOADSTOCKPRODACABADO, ORDEROPENORCLOSED, STOCKGET } from "../../constants/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { logoutSelectedMoreCompany, setSelectedMoreCompany } from "../../Store/modules/selectedMoreAbout";
import { toast } from "react-toastify";
import { FaDownload } from "react-icons/fa6";
import { logoutSelectedCompany } from "../../Store/modules/selectedCompany";
import { logoutSelectedLink } from "../../Store/modules/selectedLinkSlice";
import { SendRequest } from "../../function/General_Functions";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import MessageToUse from "../messagesToUse/MessagesToUse";
import { articleInformation } from "../messagesToUse/list";


const ICON_SIZE = 18;
const ITEMS_PER_PAGE = 20;

function TableToUse(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const selectedCompany = useSelector((state) => state.selectedCompany.data);
    const selectedLink = useSelector((state) => state.selectedLink.data);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [infoTable, setInfoTable] = useState([])
    const [columnNames, setColumnNames] = useState([]);
    const [stateTable, setStateTable] = useState("")
    const [loading, setLoading] = useState(true);
    const selectedLanguage = useSelector((state) => state.selectedLanguage.data);
    const [seeBalloon, setSeeBalloon] = useState(false)
    const { t } = useTranslation();
    const balloonRef = useRef(null);
    const [searchByName, setSearchByName] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [isChecked, setIsChecked] = useState(0);
    const [isCheckedSecond, setIsCheckedSecond] = useState(0);
    const [isCheckedThird, setIsCheckedThird] = useState(0);
    const [dateEpStart, setDateEpStart] = useState("");
    const [dateEpEnd, setDateEpEnd] = useState("");
    const [isDate, setIsDate] = useState(false);
    const [dateEfStart, setDateEfStart] = useState("");
    const [dateEfEnd, setDateEfEnd] = useState("");


    // Function to navigate to a specific page
    const goToPage = (targetPage) => {
        //console.log("targetPage", targetPage);
        if (targetPage >= 1 && targetPage <= totalPages) {
            setPage(targetPage);
        }
    };

    // Initial setup based on selected link
    useEffect(() => {
        setStateTable(selectedLink?.nameToSelect)
    }, [selectedLink?.nameToSelect]);


    // Effect to fetch data based on page change
    useEffect(() => {
        if ((selectedLink?.name === "Encomendas" || selectedLink?.name === "Artigos em Stock") && selectedCompany?.id !== "" && stateTable !== "" && selectedLanguage?.lang !== "") {
            getOrdersOpenOrClosed();
        }
        setInfoTable([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // Effect to fetch data based on company, state, and language change
    useEffect(() => {
        if (page > 1) {
            setPage(1)
        } else {
            if ((selectedLink?.name === "Encomendas" || selectedLink?.name === "Artigos em Stock") && selectedCompany?.id !== "" && stateTable !== "" && selectedLanguage?.lang !== "" && isChecked !== "") {
                getOrdersOpenOrClosed();
            }
            setInfoTable([]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany, stateTable, selectedLanguage, isChecked, isCheckedSecond, isCheckedThird, dateEpEnd, dateEfEnd]);

    // Function to show more info about a specific item
    const moreInfo = (value) => {
        //console.log("value", value);
        if (selectedLink?.name === "Encomendas") {
            navigate(`/pages/MoreAbout/${value?.ID}`)
            //console.log(value);
            dispatch(
                setSelectedMoreCompany({
                    data: {
                        value
                    }
                })
            )
        }
        //console.log("nao vai");
    }

    // GET CSV for Download
    const getCsvFileDownload = async () => {
        try {
            const token = getAuthToken();
            let apiUrl, state, stateToUrl;

            if (stateTable === "open") {
                console.log("open");
                apiUrl = DOWNLOADORDERSOPEN;
                state = "open";
                stateToUrl = "Orders"
            } else if (stateTable === "mp") {
                apiUrl = DOWNLOADSTOCKMP;
                state = "mp";
                stateToUrl = "Stock"
                console.log("mp");
            } else if (stateTable === "prodacabado") {
                apiUrl = DOWNLOADSTOCKPRODACABADO;
                state = "Produto Acabado"
                stateToUrl = "Stock"
                console.log("Produto acabado");
            } else if (stateTable === "comppart") {
                apiUrl = DOWNLOADSTOCKCOMPPART;
                state = "Component_Part"
                stateToUrl = "Stock"
            } else {
                console.log("close");
                apiUrl = DOWNLOADORDERSOPCLOSED;
                state = "closed";
                stateToUrl = "Orders"
            }

            console.log("Request URL:", `${apiUrl}${selectedCompany.id}`);

            const response = await axios.get(`${apiUrl}${selectedCompany.id}`, {
                headers: { Authorization: `token ${token}`, 'Accept-Language': selectedLanguage?.lang },
                responseType: "blob",
            });

            if (response.status === 200) {
                const currentDate = new Date().toISOString().slice(0, 10);
                const filename = `${t(stateToUrl)} ${t(state)} ${selectedCompany?.name} ${currentDate}.csv`;

                const blob = new Blob([response.data], { type: "application/octet-stream" });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                link.click();


                window.URL.revokeObjectURL(url);
            } else if (response.status === 401 && response.data.detail[0] === "Invalid Token") {
                dispatch(logoutSelectedLink());
                dispatch(logoutSelectedCompany());
                dispatch(logoutSelectedMoreCompany());
                navigate("/pages/LoginPage/");
            } else {
                throw new Error("Failed to get csv file");
            }
        } catch (error) {
            console.error("Error downloading CSV:", error);

            toast.error({
                text: "Erro ao obter o ficheiro CSV",
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch orders based on selected link and state
    const getOrdersOpenOrClosed = () => {
        if (!stateTable) {
            //console.log("stateTable is required");
            return;  // Return early if stateTable is not defined
        }

        setLoading(true);

        let baseParams = `companyid=${selectedCompany?.id}&page=${page}&records_per_page=${ITEMS_PER_PAGE}&available_st_not0=${isChecked}`;
        let baseUrl = `companyid=${selectedCompany?.id}&page=${page}&records_per_page=${ITEMS_PER_PAGE}`;
        let url = "";

        if (selectedLink?.name === "Artigos em Stock") {
            // Decide the filter based on `filterBy` state
            let filterParam = filterBy === "codarthfa" ? `codarthfa_like=${searchByName}`
                : filterBy === "description" ? `description_like=${searchByName}`
                    : "";

            let filtersToMp = stateTable === "mp" ? `reserved_st_not0=${isCheckedSecond}&ordered_st_not0=${isCheckedThird}` : "";
            url = `${STOCKGET}${stateTable}/json?${baseParams}&${filterParam}&${filtersToMp}`;
        } else {
            //console.log("aqui");
            const params = {
                "codarthfa": `codarthfa_like=${searchByName}`,
                "num_enc": `num_enc_like=${searchByName}`,
                "cod_req": `codreqcli_like=${searchByName}`,
                "product": `produto_like=${searchByName}`,
                "data_ep_gte": `data_ep_gte=${dateEpStart}`,
            }

            // Ensure that the correct parameter is appended to the base URL
            let additionalParams = params[filterBy] || ""; // This will fetch the correct query based on stateTable or be empty if no matching key.

            // Add additional parameters to the base URL
            let dateFilters = "";
            if (isDate) {
                if (filterBy === "dateEp") {
                    dateFilters = `data_ep_gte=${dateEpStart}&data_ep_lte=${dateEpEnd}`;
                } else if (filterBy === "dateEf") {
                    dateFilters = `data_ef_gte=${dateEfStart}&data_ef_lte=${dateEfEnd}`;
                }
            }


            // Construct the URL
            url = `${ORDEROPENORCLOSED}orders-${stateTable}/json?${baseUrl}&${additionalParams}&${dateFilters}`;
            //console.log("stateTable:", stateTable);  // Check the value of stateTable
            //console.log("Params:", params);  // Check the content of params
            //console.log("Selected additionalParams:", additionalParams);
        }

        console.log(selectedLanguage);
        console.log(url);  // For debugging purposes

        SendRequest("get", url, (successData) => {
            if (successData?.result === "erro") {
                navigate("/pages/LoginPage/");
            } else if (successData?.result?.result?.length === 0) {
                setInfoTable([]);
                setTotalPages(0);
                setColumnNames([]);
                setLoading(false);
            } else {
                setInfoTable(successData?.result?.result);
                setTotalPages(successData?.result?.num_pages);
                setColumnNames(Object.keys(successData?.result?.result[0]));
                setLoading(false);
            }
        }, (error) => {
            console.log("Error:", error);
        }, dispatch, selectedLanguage);
    };

    // Function to toggle visibility of the information balloon
    const iWantToSee = () => {
        setSeeBalloon(!seeBalloon)
    }

    // Effect to close the information balloon when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (balloonRef.current && !balloonRef.current.contains(event.target)) {
                iWantToSee(setSeeBalloon);  // Use the imported function
            }
        };

        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seeBalloon]);

    // Handle change on select
    const handleChange = (e) => {
        const value = e.target.value;
        setFilterBy(value);

        if (value === "") {
            setSearchByName("");  // This sets searchByName to an empty string
            setIsChecked(0)
        }
    };

    // Effect to make a request when searchByName is cleared
    useEffect(() => {
        if (searchByName === "") {
            getOrdersOpenOrClosed();  // This function is called only after searchByName is cleared
        }
    }, [searchByName]);

    // Effect to reset checkboxes when stateTable is changed
    useEffect(() => {
        setIsChecked(0)
        setIsCheckedSecond(0)
        setIsCheckedThird(0)
        setFilterBy("")
        goToPage(1);
        setSearchByName("");
    }, [stateTable, selectedLink?.name]);


    // Function to update the start date
    const updateEndDate = (dateType, value) => {
        setIsDate(true);
        if (dateType === "dateEp") {
            setDateEpEnd(value);
        } else if (dateType === "dateEf") {
            setDateEfEnd(value);
        }
    };

    // Function to reset the end date
    useEffect(() => {
        setDateEpStart("");
        setDateEpEnd("");
        setDateEfStart("");
        setDateEfEnd("");
    }, [filterBy]);

    return (
        loading ? (
            <div className="loading-spinner">
                <RingLoader size={100} color={"#123abc"} loading={loading} />
            </div>
        ) : (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: 'fit-content', marginTop: '52px' }}>
                        {selectedLink?.name === "Encomendas" ? (
                            <div className="d-flex mob">
                                <div className="mob2"> 
                                    <label>{t('Estado')}</label>
                                    <select style={{ border: '1px solid lightgray' }} value={stateTable} onChange={(e) => setStateTable(e.target.value)}>
                                        <option value="open">{t('Pendente')}</option>
                                        <option value="closed">{t('Satisfeita')}</option>
                                    </select>
                                </div>
                                <div className="ml-2 mob3">
                                    <label>{t('Filtrar por')}</label>
                                    <select value={filterBy} onChange={handleChange}>
                                        <option value="">{t('Todos')}</option>
                                        <option value="num_enc">{t('Numero Encomenda HFA')}</option>
                                        <option value="codarthfa">{t('Código Artigo HFA')}</option>
                                        <option value="cod_req">{t('Código Requisição Cliente')}</option>
                                        <option value="product">{t('Produto')}</option>
                                        <option value="dateEp">{t('Data Entrega Prevista')}</option>
                                        <option value="dateEf">{t('Data Entrega Efetiva')}</option>
                                    </select>
                                </div>
                                {filterBy !== "" && filterBy !== "dateEp" && filterBy !== "dateEf" ? (
                                    <div className="ml-2 mob3">
                                        <label>{t('Procurar')}</label>
                                        <input
                                            value={searchByName} onChange={(e) => setSearchByName(e.target.value)}
                                            type="text"
                                            placeholder={t('Procurar')} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    getOrdersOpenOrClosed();
                                                }
                                            }} />
                                    </div>)
                                    :
                                    ""
                                }
                                {filterBy === "dateEp" ? (
                                    <>
                                        <div className="ml-2 mob3">
                                            <label>{t('Data Entrega Prevista Inicio')}</label>
                                            <input type="date" value={dateEpStart} onChange={(e) => {
                                                setIsDate(true)
                                                setDateEpStart(e.target.value)
                                            }} />
                                        </div>
                                        <div className="ml-2 mob3">
                                            <label>{t('Data Entrega Prevista Fim')}</label>
                                            <input
                                                type="date"
                                                value={dateEpEnd}
                                                onChange={(e) => updateEndDate("dateEp", e.target.value)}
                                            />
                                        </div>
                                    </>
                                ) : filterBy === "dateEf" ? (
                                    <>
                                        <div className="ml-2 mob3">
                                            <label>{t('Data Entrega Efetiva Inicio')}</label>
                                            <input type="date" value={dateEfStart} onChange={(e) => {
                                                setIsDate(true)
                                                setDateEfStart(e.target.value)
                                            }} />
                                        </div>
                                        <div className="ml-2 mob3">
                                            <label>{t('Data Entrega Efetiva Fim')}</label>
                                            <input
                                                type="date"
                                                value={dateEfEnd}
                                                onChange={(e) => updateEndDate("dateEf", e.target.value)}
                                            />
                                        </div>
                                    </>
                                ) : ""}
                            </div>
                        ) : (
                            <div className="d-flex mob">
                                <div className="mob2">
                                    <label>{t('Tipo')}</label>
                                    <select value={stateTable} onChange={(e) => setStateTable(e.target.value)}>
                                        <option value="mp">{t('Matéria Prima')}</option>
                                        <option value="prodacabado">{t('Produto Acabado')}</option>
                                        <option value="comppart">{t('Component Part')}</option>
                                    </select>
                                </div>
                                <div className="ml-2 mob3">
                                    <label>{t('Filtrar por')}</label>
                                    <select value={filterBy} onChange={handleChange}>
                                        <option value="">{t('Todos')}</option>
                                        <option value="codarthfa">{t('Código Artigo HFA')}</option>
                                        <option value="description">{t('Descrição')}</option>
                                    </select>
                                </div>
                                {filterBy === "" ? (
                                    ""
                                ) : (
                                    <div className="ml-2 mob3">
                                        <label>{t('Procurar')}</label>
                                        <input
                                            value={searchByName} onChange={(e) => setSearchByName(e.target.value)}
                                            type="text"
                                            placeholder={t('Procurar')} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    getOrdersOpenOrClosed();
                                                }
                                            }} />
                                    </div>
                                )}
                                {stateTable === "prodacabado" ? (
                                    <div className="ml-2 d-flex align-items-center flex-row-reverse mob4 mob3">
                                        <label>{t('Stock Disponivel 0')}</label>
                                        <input
                                            style={{ height: '58.87px', appearance: 'auto', width: '15px', alignItems: 'center', marginRight: '8px' }}
                                            type="checkbox"
                                            defaultChecked={isChecked === 0}
                                            value={isChecked === 0}
                                            onChange={(e) => setIsChecked(e.target.checked ? 0 : 1)}
                                        />
                                    </div>
                                ) : (
                                    stateTable === "mp" ? (
                                        <>
                                            <div className="ml-2 d-flex align-items-center flex-row-reverse mob4 mob3">
                                                <label>{t('Stock Disponivel 0')}</label>
                                                <input
                                                    style={{ height: '58.87px', appearance: 'auto', width: '15px', alignItems: 'center', marginRight: '8px' }}
                                                    type="checkbox"
                                                    defaultChecked={isChecked === 0}
                                                    value={isChecked === 0}
                                                    onChange={(e) => setIsChecked(e.target.checked ? 0 : 1)}
                                                />
                                            </div>
                                            <div className="ml-2 d-flex align-items-center flex-row-reverse mob4 mob3">
                                                <label>{t('Stock Reservado 0')}</label>
                                                <input
                                                    style={{ height: '58.87px', appearance: 'auto', width: '15px', alignItems: 'center', marginRight: '8px' }}
                                                    type="checkbox"
                                                    defaultChecked={isCheckedSecond === 0}
                                                    value={isCheckedSecond === 0}
                                                    onChange={(e) => setIsCheckedSecond(e.target.checked ? 0 : 1)}
                                                />
                                            </div>
                                            <div className="ml-2 d-flex align-items-center flex-row-reverse mob4 mob3">
                                                <label>{t('Stock Encomendado 0')}</label>
                                                <input
                                                    style={{ height: '58.87px', appearance: 'auto', width: '15px', alignItems: 'center', marginRight: '8px' }}
                                                    type="checkbox"
                                                    defaultChecked={isCheckedThird === 0}
                                                    value={isCheckedThird === 0}
                                                    onChange={(e) => setIsCheckedThird(e.target.checked ? 0 : 1)}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="ml-2 d-flex align-items-center flex-row-reverse mob4 mob3">
                                            <label>{t('Stock Disponivel 0')}</label>
                                            <input
                                                style={{ height: '58.87px', appearance: 'auto', width: '15px', alignItems: 'center', marginRight: '8px' }}
                                                type="checkbox"
                                                defaultChecked={isChecked === 0}
                                                value={isChecked === 0}
                                                onChange={(e) => setIsChecked(e.target.checked ? 0 : 1)}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <button style={{ background: 'none', border: 'none' }} onClick={() => iWantToSee()} onMouseOver={() => iWantToSee()} >
                            <FaInfoCircle color={'blue'} size={ICON_SIZE} />
                        </button>
                        <button className="ml-2" style={{ background: 'none', border: 'none' }} onClick={() => getCsvFileDownload()}><FaDownload size={ICON_SIZE} /></button>
                        {seeBalloon === true ? (
                            <div ref={balloonRef} className="informationBalloon">
                                <small>
                                    {articleInformation.map((item) =>
                                        item.state === stateTable ? (
                                            <MessageToUse key={item.label} title={t(item.label)} text={t(item.description)} />
                                        ) : (<></>)
                                    )}
                                </small>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <>
                    <div className="mt-4" style={{ overflowX: 'auto' }}>
                        <table className="mt-4">
                            <thead style={{ borderBottom: columnNames.length > 0 ? '1px solid lightgray' : "none" }} className="colorHead">
                                {infoTable.length > 0 ? (
                                    <tr>
                                        {columnNames
                                            .map((columnName) => (
                                                <th key={columnName}>{columnName}</th>
                                            ))}
                                    </tr>
                                ) : (
                                    <></>
                                )}
                            </thead>
                            <tbody>
                                {infoTable.length > 0 ? (
                                    infoTable.map((value, index) => (
                                        <tr style={{ cursor: selectedLink?.name === "Encomendas" ? 'pointer' : "auto" }} onClick={() => moreInfo(value)} className="tableTr" key={value.FACLIId || index}>
                                            {Object.keys(value)
                                                .map((key) => (
                                                    <td className="hideText" key={key} title={value[key]}>{value[key]}</td>
                                                ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="test2"></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {infoTable.length > 0 ? (
                            <div className="d-flex justify-content-flex-end align-items-center mt-1">
                                <span
                                    style={{
                                        borderRight: "1px solid lightgray",
                                        paddingRight: "32px",
                                        marginRight: "32px",
                                    }}
                                >
                                    Página {page} of {totalPages}
                                </span>
                                <button className="btnTable" onClick={() => goToPage(page - 1)}>
                                    <BiLeftArrow size={ICON_SIZE} />
                                </button>
                                <button
                                    className="btnTable"
                                    style={{
                                        marginRight: "32px",
                                    }}
                                    onClick={() => goToPage(page + 1)}
                                >
                                    <BiRightArrow size={ICON_SIZE} />
                                </button>
                                <input
                                    style={{ width: "64px" }}
                                    type="number"
                                    value={page}
                                    onChange={(e) => setPage(parseInt(e.target.value, 10))}
                                />
                            </div>
                        ) : (<></>)}
                    </div>
                </>
            </>
        )
    );

}

export default TableToUse;
