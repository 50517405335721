import { createSlice } from "@reduxjs/toolkit";

export const selectedLanguage = createSlice({
  name: "selectedLanguage",
  initialState: {
    data: {
      lang: 'pt'
    },
  },
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.data = action.payload.data;
    },
    logoutSelectedLanguage: (state) => {
      state.data = {
        lang: 'pt'
      }
    }
  },
});

// Export the action creator separately
export const { setSelectedLanguage, logoutSelectedLanguage } = selectedLanguage.actions;

// Export the reducer
export default selectedLanguage.reducer;
