import { createSlice } from "@reduxjs/toolkit";

export const selectedCompanySlice = createSlice({
  name: "selectedCompany",
  initialState: {
    data: null,
  },
  reducers: {
    setSelectedCompany: (state, action) => {
      state.data = action.payload.data;
    },
    logoutSelectedCompany: (state) => {
      state.data = null;
    },
  },
});

// Export the action creator separately
export const { setSelectedCompany, logoutSelectedCompany } = selectedCompanySlice.actions;

// Export the reducer
export default selectedCompanySlice.reducer;
