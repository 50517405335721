import { createSlice } from "@reduxjs/toolkit";

export const selectedLinkSlice = createSlice({
  name: "selectedLink",
  initialState: {
    data: null,
  },
  reducers: {
    setSelectedLink: (state, action) => {
      state.data = action.payload.data;
    },
    logoutSelectedLink: (state) => {
      state.data = null;
    },
  },
});

// Export the action creator separately
export const { setSelectedLink, logoutSelectedLink } = selectedLinkSlice.actions;

// Export the reducer
export default selectedLinkSlice.reducer;
