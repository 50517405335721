import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function withAuth(Component) {
  return function AuthenticatedComponent(props) {
    const navigate = useNavigate();


    useEffect(() => {
      const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
      if (!loggedInUser) {
        //console.log("loggedInUser", loggedInUser);
        //console.log("validacao");
        navigate('/pages/LoginPage/');
        localStorage.removeItem('loggedInUser');
      }
    }, [navigate]);


    return <Component {...props} />;
  }
}

export default withAuth;